Foundation.Drilldown.defaults.backButton = '<li class="js-drilldown-back"><a tabindex="0">Zpět</a></li>';
Foundation.Drilldown.defaults.autoHeight = true;
Foundation.Drilldown.defaults.animateHeight = true;
// Foundation.DropdownMenu.defaults.alignment = 'right';

$(document).foundation();

$(document).ready(function () {
    /*
     Nevím, defaults ani data option mi nějak nejde
     */
    $('.menu > li').not(':last-child').removeClass('opens-left').addClass('opens-right');
});

let vide_options = {
    poster: '/files/images/header_Bg',
    posterType: 'jpg'
};

if (Foundation.MediaQuery.atLeast('medium')) {
    vide_options.mp4 = '/files/videos/header';
}

$('#background_video').vide(
    vide_options
);

AOS.init();

lightbox.option(
    {
        'albumLabel': '%1 / %2',
    }
);


let $youtubeSlider = $('#youtube_slider');
$youtubeSlider.slick(
    {
        lazyLoad: 'ondemand',
        dots: true,
// 		arrows       : false,
        autoplay: true,
        autoplaySpeed: 5000
    }
);


$youtubeSlider.find('a')
    .on('mousedown', function (e) {
        $(this).data('moving', false);
    })
    .on('mousemove', function (e) {
        $(this).data('moving', true);
    })
    .on('mouseup', function (e) {
        if (!$(this).data('moving')) {
            let $that = $(this);
            e.preventDefault();
            $.post($that.data('href'), null, function (data) {
                $('#video_modal').html(data).foundation('open');
            });
        }
    });

$('[data-ajax-load]').on('click', function (e) {
    e.preventDefault();
    let $this = $(this);
    let href = $this.attr('href');

    $.post(href, null, function (data) {
        $('#universalModal').html(data).foundation('open');
    });
});

$('.formular-view-3 .wrap')
    .each(function () {
        let $that = $(this);
        let label = $that.children('.formInput-Label').remove();
        let placeholder = label.text().trim().toUpperCase();
        $that.find('input, textarea').attr('placeholder', placeholder).attr('required', 'required');
    });

/*
 Obalení třídy stylem pro město, abych zbytečně nepřidával nesmysly do php
 */
$('.news-category-1').parent().parent().wrap($('<div/>', {class: 'city_wrap'}));
$('.sponsors_logos').parent().wrap($('<div/>', {class: 'sponsors_logos_wrap'}));
$('#youtube_slider').parent().parent().addClass('youtube_slider_wrap');
$('.whatWeDoForYouBoxes').parent().addClass('whatWeDoForYouBoxesWrap');